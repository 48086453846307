<template>
  <fw-layout wide back-to="/manage" management mobile-ready>
    <template #main-content>
      <PanelNotifications :active-filters="{}" />
    </template>
  </fw-layout>
</template>

<script>
import PanelNotifications from '@/fw-modules/fw-core-vue/ui/components/panels/PanelNotifications'

export default {
  name: 'ManageNotifications',
  components: {
    PanelNotifications,
  },
  computed: {
    api() {
      return this.$store.state.api.base
    },
  },
}
</script>
